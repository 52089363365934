import React from 'react'
import './footer.css'
import gpt3Logo from '../../assets/logo.svg'
const Footer = () => {
  return (
    <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text"> Do you want in to the future before others</h1>
    </div>
    <div className="gpt3__footer-btn">
      <p>Request Early Access</p>
    </div>
    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="logo"/>
        <p>NAIROBI KENYA 00100</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>social media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions</p>
        <p>Privacy Policy</p>
        <p>Contact</p>
  
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in Touch</h4>
        <p>NAIROBI KENYA</p>
        <p>0700 001 002</p>
        <p>info@555Africa.com</p>
      </div>
    </div>
    <div className="gpt3__footer-copyright">
      <p>@2022 555 Africa. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer